import React from "react";
import { Modal } from "react-bootstrap";
import cancelIcon from "../assets/images/circle-xmark 1.svg";

const ResourcesModal = ({ modalUrl, setModalUrl }) => {
  return (
    modalUrl && (
      <Modal
        show={!!modalUrl}
        centered
        dialogClassName="custom-modal-dialog"
        contentClassName="p-0"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Modal.Dialog className="m-0">
          <Modal.Body
            className="p-0"
            style={{
              backgroundColor: "transparent",
              position: "relative",
              overflow: "visible",
            }}
          >
            <img
              src={modalUrl}
              className="modal-image"
              style={{
                display: "block",
              }}
            />

            <img
              onClick={() => setModalUrl(null)}
              src={cancelIcon}
              style={{
                position: "absolute",
                top: "-10px",
                right: "-5px",
                cursor: "pointer",
              }}
            />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    )
  );
};

export default ResourcesModal;
