import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { FaSearch } from "react-icons/fa";
import { getAllPartner, getPartnersChart } from "../../services/partner/getAllPartner";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import { useNavigate } from "react-router-dom";
import DataTables from "../../component/DataTable";
import PartnerDatatableMenu from "../../component/partnerDatatableMenu";
import deletePartner from "../../services/partner/deletePartner";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import deleteIcon from "../../assets/images/delete-icon.svg";
import { Bar, Line } from "react-chartjs-2";
import { GoDotFill } from "react-icons/go";
import {
  Box,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import Graph from "./view/Graph";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);
const PartnerListing = () => {
  const { t } = useTranslation();
  const { currentLocalization, setToastId } = useContext(AuthUserContext);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [partner, setPartner] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [deleteLoading, setDleteLoading] = useState(false)
  const [partnerName, setPartnerName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const handlePartnerDelete = async (id, companyName) => {
    setModalShow(true)
    setSectionId(id)
    setPartnerName(companyName);
  }
  const handleDeletePartner = async () => {
    try {
      setDleteLoading(true)
      const response = await deletePartner(sectionId);
      if (response) {
        const toastId = toast.success(response.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
        setToastId(toastId);
        setModalShow(false);
        setSortedUsers((prevSections) => {
          const updatedSections = prevSections.filter((section) => section._id !== sectionId);
          return updatedSections;
        });
      }
    } catch (err) {
      if (err.response?.status === 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        localStorage.clear();
        navigate("/log-in");
      } else {
        console.log("err", err);
        const id = toast.error(err.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setDleteLoading(false)
    }
  }

  const columns = [
    {
      name: <span className="dataTable-text">{t("partnerListing.companyName")}</span>,
      key: "companyName",
      selector: (row) => row.package,
      cell: (row) => (
        <div className="text-start column-text d-flex align-items-center mt-2 mb-2">
          <img src={row?.companyLogo} alt="companyLogo" className="image-patner" />
          <span className="ps-2">{row?.companyName}</span>
        </div>
      ),
      sortable: true,
      width: "250px"
    },
    {
      name: <span className="dataTable-text">{t("partnerListing.companyEmail")}</span>,
      key: "email",
      cell: (row) => (
        <span className="text-start column-text">{row?.email}</span>
      ),
      sortable: true,
      width: "220px"
    },
    {
      name: <span className="dataTable-text">{t("partnerListing.companyDomain")}</span>,
      key: "domain",
      cell: (row) => (
        <span className="text-start column-text">{row?.domain}</span>
      ),
      sortable: true,
      width: "170px"
    },
    {
      name: <span className="dataTable-text">{t("partnerListing.commissionPercentage")}</span>,
      key: "commissionPercentage",
      selector: (row) => <div className="column-text"><strong>{row?.commissionPercentage}%</strong> at <strong>{row?.commissionAmount}$</strong> Minimum Sale</div>,
      sortable: true,
      width: "250px"
    },
    {
      name: <span className="dataTable-text">{t("TotalSales")}</span>,
      key: "totalPrice",
      cell: (row) => (
        <span className="column-text">${row?.totalPrice}</span>
      ),
      sortable: true,
      sortFunction: (rowA, rowB) => rowA.totalPrice - rowB.totalPrice,
    },
    {
      name: <span className="dataTable-text">{t("TotalCommision")}</span>,
      key: "totalPrice",
      cell: (row) => {
        const commissionA = (Number(row?.totalPrice) || 0) > (Number(row?.commissionAmount) || 0)
          ? ((Number(row?.totalPrice) || 0) * (Number(row?.commissionPercentage) || 0)) / 100
          : 0;

        return <span className="column-text">${commissionA.toFixed(2)}</span>;
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const commissionA = rowA.totalPrice > rowA.commissionAmount
          ? (rowA.totalPrice * rowA.commissionPercentage) / 100
          : 0;

        const commissionB = rowB.totalPrice > rowB.commissionAmount
          ? (rowB.totalPrice * rowB.commissionPercentage) / 100
          : 0;

        return commissionA - commissionB;
      },
      width: "150px"
    },
    {
      name: <span className="dataTable-text">{t("partnerListing.action")}</span>,
      selector: (row) => (
        <span className="column-text">
          <PartnerDatatableMenu onDelete={handlePartnerDelete} row={row} />
        </span>
      ),
    },
  ];

  const handleSort = async (column, direction) => {
    setSortField(column.key);
    setSortOrder(direction === "asc" ? "asc" : "desc");
    const response = await getAllPartner({ search: '', sortField: column.key, sortOrder: direction === "asc" ? "asc" : "desc" });
    setSortedUsers(response.data);
  };
  const handleSearchChange = async (e) => {
    setSearch(e.target.value);
    const response = await getAllPartner({ search: e.target.value, sortField, sortOrder });
    setSortedUsers(response.data);
  };

  const getPartner = async () => {
    try {
      const response = await getAllPartner({ search, sortField, sortOrder });
      // setPartner(response.data);
      setSortedUsers(response.data);
    } catch (err) {
      const userDetails = JSON.parse(localStorage.getItem("USER_DETAILS"));
      const userRole = userDetails?.role;
      if (userRole === "member") {
        navigate("/content-management");
        return;
      }
      if (userRole === "user") {
        navigate("/data-usage");
        return;
      }
      if (err.response?.status === 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        localStorage.clear();
        navigate("/log-in");
      } else {
        console.log("err", err);
        const id = toast.error(err.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPartner();
  }, []);

  return (
    <Sidebar>
      <Container className="p-0">
        <Row className="p-2">
          <Col className="p-md-0">
            <Row className="d-flex align-items-center justify-content-between " style={{ marginTop: "65px" }}>
              <Col className={currentLocalization === "ar" ? "text-end" : "text-start"}>
                <h2 className="package-h2 mt-0">{t("dataUsage.PartnerListing")}</h2>
              </Col>
              <Col xl={8} lg={12} md={11} xs={12} className=" pe-0">
                <Row className=" d-flex align-items-center justify-content-end">
                  <Col xl={5} lg={6} md={6} xs={12} className=" p-0 mt-md-0 mt-3 pe-md-0 pe-3">
                    <div className="search-input d-flex align-items-center">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search Partner"
                        className="offdK-search ps-3"
                        onChange={handleSearchChange}
                        value={search}
                      />
                      <FaSearch size={20} color="#215B5B" />
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6} xs={12} className=" d-flex justify-content-center  pe-md-0 mt-md-0 mt-3">
                    <button className="btn-add-new btn-add-new-font" onClick={() => navigate("/add-partner")}>
                      {t("partnerListing.addPartner")}
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>



        {/* <Box sx={{ mt: 3, p: 2, borderRadius: 2, boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", backgroundColor: "#ffffff" }}> */}
          <Row className="p-2">
            <Col className="total-sales-box p-2 mt-3">
              <div className={currentLocalization === "ar" ? "text-end d-flex justify-content-between align-items-center mb-3 flex-row-reverse ps-2 pe-2 flex-wrap" : "text-start d-flex justify-content-between align-items-center mb-3 ps-2 pe-2 flex-wrap"}>
                <h2 className="package-h4 mt-2">{t("Total Sales")}</h2>
              </div>
              <div className={currentLocalization === "ar" ? "text-end" : "text-start"}></div>
              <Graph />
            </Col>
          </Row>

        {/* </Box> */}

        {/* <Row> 
          <Col className=" p-0"> */}
            <div className="mt-5">
              <DataTables
                columns={columns}
                data={sortedUsers}
                loading={loading}
                handleSort={handleSort}
              />
            </div>
          {/* </Col>
        </Row> */}
      </Container>
      {modalShow ? (
        <Modal
          show={modalShow}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-width"
        >
          <Modal.Body className="p-4 ">
            <div className="d-flex justify-content-center flex-column align-items-center">
              <img src={deleteIcon} alt="deleteIcon" width={150} />
            </div>
            <h4 className="modal-h4 text-center mt-4">
              Are you sure you want to delete <strong>{partnerName}</strong>?
            </h4>
            <Row>
              <Col md={6} xs={12}>
                <div
                  className="d-grid gap-2"
                  onClick={() => {
                    setModalShow(false);
                  }}
                  disabled={deleteLoading}
                >
                  <button className={`modal-button-cancel mt-4 ${deleteLoading ? "disabled-btn" : ""}`} size="lg">
                    Cancel
                  </button>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="d-grid gap-2">
                  <button
                    className="modal-button-save mt-4"
                    size="lg"
                    onClick={handleDeletePartner}
                    disabled={deleteLoading}
                  >
                    {deleteLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ms-1">Loading...</span>
                      </>
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </Sidebar>
  );
};

export default PartnerListing;
