import React from "react";

const CustomMenu = ({menuItems,isMenuOpen,handleItemClick}) => {
  return (
    isMenuOpen && (
      <div className="menu">
        {menuItems.map((item, index) => (
          <button
            key={index}
            className="menu-item"
            onClick={() => handleItemClick(item)}
          >
            {item.label}
          </button>
        ))}
      </div>
    )
  );
};

export default CustomMenu;
