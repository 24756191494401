import React, { useContext, useState } from "react";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import docx from "../../assets/images/docx.svg";
import { downloadFile } from "../../helper";
import { AuthUserContext } from "../../context/context";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import { toast } from "react-toastify";
import deleteResourceEndpoint from "../../services/partner/deleteResource";
import ConfirmationModal from "../../component/ConfirmationModal";
const DesktopDocCard = ({ fileName, link, setResources, resourceId }) => {
  
  const { user, setToastId } = useContext(AuthUserContext);
  const [loading, setLoading] = useState(false);
  const [isResourceId, setIsResourceId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const confirmationModalOpen = (resourceId) => {
    setModalShow(true);
    setIsResourceId(resourceId);
  };
  const confirmationModalClose = () => {
    setModalShow(false);
    setIsResourceId("");
  };
  const deleteResource = async () => {
    try {
      setLoading(true);
      await deleteResourceEndpoint(isResourceId);
      setResources((prevResources) =>
        prevResources.filter((resource) => resource._id !== isResourceId)
      );
      setModalShow(false);
      toast.success("Resource has been deleted successfully.");
    } catch (error) {
      const id = toast.error(error, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
      setToastId(id);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="card-wrapper pdf-card-wrapper">
      <div className="pdf-card">
        <img src={docx} />
        <p className="cardText">{fileName}</p>
      </div>
      <div className="overlay"></div>
      <div className={user?.role === "admin" ? "active-doc-icon" : "active-doc-icon-partner"}>
        <div className="images">
          <img
            src={downloadIcon}
            className=""
            onClick={() => downloadFile(link, fileName)}
          />
          {user?.role === "admin" && (
            <img
              src={deleteIcon}
              onClick={() => confirmationModalOpen(resourceId)}
            />
          )}
        </div>
      </div>
      <ConfirmationModal
        loading={loading}
        deleteResource={deleteResource}
        confirmationModalClose={confirmationModalClose}
        modalShow={modalShow}
      />
    </div>
  );
};

export default DesktopDocCard;
