import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import { toast } from "react-toastify";
import DataTables from "../../component/DataTable";
import { useNavigate } from "react-router-dom";
import getTransactionsList from "../../services/transactions/getTransactionsList";
import { USER_DETAILS } from "../../constants/localstorageKeys";
import esimVaultSvg from "../../assets/images/esimVault.svg";
import airaloSvg from "../../assets/images/airalo.svg";
import esimgoSvg from "../../assets/images/esimgo.svg";
import MonthSelector from "../../component/MonthFilter";
import TotalSale from "../../assets/images/Icon (2).svg";
import info from "../../assets/images/info.svg"
import TotalCustomers from "../../assets/images/Icon (1).svg";
import TotalCommission from "../../assets/images/Icon (3).svg";
import Search from "../../component/Search";
import getUsersCardData from "../../services/packages/getUsersCardData";
import DateFilter from "../../component/DateFilter";
import ApplyFilterButton from "../../component/ApplyFilter";
const TransactionsListing = () => {
  const { t } = useTranslation();
  const { currentLocalization, setToastId } = useContext(AuthUserContext);
  const [selectedMonth, setSelectedMonth] = useState('null');
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [user, setUser] = useState();
  const [monthlyData, setMonthlyData] = useState("");
  const [monthlyCommision, setMonthlyCommision] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minDate, setMinDate] = useState("");
  const [appliedStartDate, setAppliedStartDate] = useState('');
  const [appliedEndDate, setAppliedEndDate] = useState('');

  const [sortedUsers, setSortedUsers] = useState([]);
  const [commission, setCommission] = useState("");

  const handleSearchChange = (newSearchValue) => {
    getTransactionsListing(null, null, newSearchValue);
    setSearch(newSearchValue);
  };
  const getTransactionsListing = async (startDate = null, endDate = null, search = "") => {
    setLoading(true)
    try {
      const usersResponse = await getTransactionsList({ startDate, endDate, search });
      if (usersResponse.success) {
        setTransactions(usersResponse?.data.payments);
        setSortedUsers(usersResponse?.data.payments);
        setStartDate(usersResponse.data.startDate);
        setEndDate(usersResponse.data.endDate);
        if(!minDate) setMinDate(usersResponse.data.startDate)
        if (!appliedStartDate && !appliedEndDate) {
          setStartDate(usersResponse.data.startDate);
          setEndDate(usersResponse.data.endDate);
        }
      }
    } catch (err) {
      const userDetails = JSON.parse(localStorage.getItem("USER_DETAILS"));
      const userRole = userDetails?.role;
      if (userRole === "member") {
        navigate("/content-management");
        return;
      }
      if (userRole === "user") {
        navigate("/data-usage");
        return;
      }
      if (err.response?.status === 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        localStorage.clear();
        navigate("/log-in");
      } else {
        console.log("err", err);
        const id = toast.error(err.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  const fetchData = async (startDate = null, endDate = null) => {
    try {
      const [usersResponse] = await Promise.all([
        getUsersCardData({ startDate, endDate }),
      ]);

      if (usersResponse.success) {
        setMonthlyData(usersResponse.data.totalPrice);
        setMonthlyCommision(usersResponse.data.commission);
        setStartDate(usersResponse.data.startDate);
        setEndDate(usersResponse.data.endDate);
        setCommission(usersResponse.data.commissionAmount || 0)
        if (!appliedStartDate && !appliedEndDate) {
          setStartDate(usersResponse.data.startDate);
          setEndDate(usersResponse.data.endDate);
        }
      }
    } catch (err) {
      console.error(err.message);
    } finally {
    }
  };
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));

    // Only run fetchData if userDetails exists and role is 'partner'
    if (userDetails && userDetails.role === 'partner') {
      fetchData(appliedStartDate, appliedEndDate);
    }
  }, [appliedStartDate, appliedEndDate]);
  useEffect(() => {

    getTransactionsListing(appliedStartDate, appliedEndDate);
  }, [appliedStartDate, appliedEndDate]);

  const handleApplyFilter = () => {

    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
  };
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
    }
  }, []);

  const columns = [
    {
      name: <span className="dataTable-text">{t("table.vendor")}</span>,
      key: "package.key",
      cell: (row) => (
        <div className="text-start column-text d-flex align-items-center">
          <div className="" style={{ width: "55px" }}>
            <img
              src={
                row?.package && row?.package?.key === "esimgo"
                  ? esimgoSvg
                  : row?.package?.key === "esimVault"
                    ? esimVaultSvg
                    : airaloSvg
              }
              alt="offer-icon"
              width={40}
            />
          </div>
          <span className="ms-2">{row?.package && row?.package.key}</span>
        </div>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: <span className="dataTable-text">{t("table.packageName")}</span>,
      key: "package.name",
      cell: (row) => (
        <span className="text-start column-text">
          {row?.package && row?.package?.name && row.package?.name?.length > 35
            ? `${row?.package?.name.slice(0, 35)}...`
            : row?.package?.name}
        </span>
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: <span className="dataTable-text">{t("table.username")}</span>,
      key: "userId.name",
      selector: (row) => (
        <span className="text-start column-text">{row?.userId?.name ?? row?.name}</span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="dataTable-text">{t("table.email")}</span>,
      key: "userId.email",
      selector: (row) => (
        <span className="text-start column-text">{row?.userId?.email ?? row?.email}</span>
      ),
      sortable: true,
      width: "280px",
    },
    {
      name: <span className="dataTable-text">{t("table.validDays")}</span>,
      key: "package.validity",
      selector: (row) => (
        <span className="text-start column-text">
          {row?.package?.validity} {t("home.part21")}
        </span>
      ),
      sortable: true,
      width: currentLocalization === "in" ? "200px" : "170px",
    },
    {
      name: <span className="dataTable-text">{t("table.Data")}</span>,
      key: "package.data",
      selector: (row) => (
        <span className="text-start column-text">
          {row?.package?.data < 0 ? row?.package?.data : t("home.part33")} {row?.package?.data < 0 && t("home.part22")}
        </span>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: <span className="dataTable-text">{t("table.orignalPrice")}</span>,
      key: "discountedPrice",
      selector: (row) => (
        <span className="text-start column-text">
          $ {row.event === 'topup' ? parseFloat(row?.priceInCents / 100).toFixed(2) : parseFloat(row?.priceInCents).toFixed(2)}
        </span>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: <span className="dataTable-text">{t("table.Discount")}</span>,
      key: "discountedPer",
      selector: (row) => (
        <span className="text-start column-text">
          {row.discountedPer ? row.discountedPer + "%" : '-'}
        </span>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: <span className="dataTable-text">{t("table.Price")}</span>,
      key: "price",
      selector: (row) => (
        <span className="text-start column-text">
          $ {
            row.event === 'topup' ?
              (row?.discountedPrice ? parseFloat(row?.discountedPrice).toFixed(2) : parseFloat(row?.priceInCents / 100).toFixed(2))
              : (row?.discountedPrice ? parseFloat(row?.discountedPrice).toFixed(2) : parseFloat(row?.priceInCents).toFixed(2))
          }
        </span>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: <span className="dataTable-text">{t("table.timestamps")}</span>,
      key: "createdAt",
      selector: (row) => (
        <span className="text-start column-text">
          {new Date(row.createdAt).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </span>
      ),
      sortable: true,
      width: currentLocalization === "in" ? "210px" : "170px",
    },
    {
      name: <span className="dataTable-text">{t("table.event")}</span>,
      key: "event",
      selector: (row) => (
        <span className="text-start column-text">
          {row?.event === "simPurchase" ? "Sim Purchased" : "Topup"}
        </span>
      ),
      sortable: true,
      width: "180px",
    },
  ];

  const handleSort = async (column, direction) => {
    const nestedSort = (obj, path) => {
      return path
        .split(".")
        .reduce(
          (value, key) =>
            value && value[key] !== undefined ? value[key] : null,
          obj
        );
    };

    const sortedData = [...transactions].sort((a, b) => {
      const aValue = nestedSort(a, column.key);
      const bValue = nestedSort(b, column.key);
      if (aValue === null || aValue === undefined)
        return direction === "asc" ? 1 : -1;
      if (bValue === null || bValue === undefined)
        return direction === "asc" ? -1 : 1;
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setSortedUsers(sortedData);
  };

  return (
    <Sidebar>
      <Container>
        <Row className="d-flex align-items-center justify-content-between">

          <Col xs="auto">
            <div
              className={
                currentLocalization === "ar" ? "text-end" : "text-start"
              }
            >
              <h2 className="package-h2">{t("dataUsage.transactionsListing")}</h2>
            </div>
          </Col>
          <Col xs="auto" className="d-flex justify-content-end align-items-center gap-2 flex-wrap" >
            {user && user.role === 'partner' && (<>
              {/* <Search
                placeholder="Search"
                onSearchChange={handleSearchChange}
              /> */}
              <DateFilter
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                initialStartDate={startDate}
                initialEndDate={endDate}
                minDate={minDate}
              />
              <ApplyFilterButton onApply={handleApplyFilter} />
            </>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          {user && user.role === 'partner' && (
            <>
              <Col xl={4} lg={4} md={12} xs={12} className="text-start mt-2">
                <div className="d-flex aligin-items-center ps-2 analytics-card-box pt-3 pb-3">
                  <div className="d-flex">
                    <img
                      src={TotalSale}
                      alt="Total Customers Icon"
                      className="customer-icon"
                    />
                    <div className="ps-3">
                      <h5 className="card-value-h5">{`$${monthlyData || "0.00"}`}</h5>
                      <h6 className="card-value-h6">{t("Total Sales")}</h6>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="mt-2 text-start">
                <div className="d-flex aligin-items-center ps-2 analytics-card-box pt-3 pb-3">
                  <div className="d-flex">
                    <img
                      src={TotalCommission}
                      alt="Total Commission Icon"
                      className="customer-icon"
                    />
                    <div className="ps-3">
                      <h5 className="card-value-h5">{`$${monthlyCommision || "0.00"}`}</h5>
                      <h6 className="card-value-h6">{t("Total Commission")}</h6>
                    </div>
                  </div>
                  <div className="info-container">
                    <img src={info} alt="Info" className="info-icon" />
                    <span className="info-text">
                      On Minimum Sale: <strong>${commission}</strong>
                    </span>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>

        <Row>
          <Col>
            <div className="mt-4">
              <DataTables
                columns={columns}
                data={sortedUsers.length ? sortedUsers : transactions}
                loading={loading}
                handleSort={handleSort}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Sidebar>
  );
};

export default TransactionsListing;
