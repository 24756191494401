import axios from "axios";
import routes from "../../configs/api";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";


const userProfile = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);

    const URL = routes.USER_PROFILE;
    axios
      .get(URL,{
        headers: {
            Authorization: `Bearer ${token}`,
          },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default userProfile;
