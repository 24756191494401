import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import getPackagesDetails from "../../services/packages/getPackages";
import { USER_DETAILS } from "../../constants/localstorageKeys";
import { toast } from "react-toastify";
import DataTables from "../../component/DataTable";
import { useNavigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { images } from "../../utils/image";
import MonthSelector from "../../component/MonthFilter";
import Search from "../../component/Search";
import DateFilter from "../../component/DateFilter";
import ApplyFilterButton from "../../component/ApplyFilter";
const MyPackage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentLocalization, setToastId, user, setUser } = useContext(AuthUserContext);
  const [selectedMonth, setSelectedMonth] = useState('null');
  const [search, setSearch] = useState('');
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [minDate, setMinDate] = useState("");

  const [endDate, setEndDate] = useState("");
  const [sortedUsers, setSortedUsers] = useState([]);
  const [appliedStartDate, setAppliedStartDate] = useState('');
  const [appliedEndDate, setAppliedEndDate] = useState('');
  const columns = [
    {
      name: <span className="dataTable-text">{t("table.vendor")}</span>,
      key: "vendorType",
      cell: (row) => (
        <div className="text-start column-text d-flex align-items-center">
          <div className="" style={{ width: "55px" }}>
            <img
              src={
                row?.vendorType == "esimgo"
                  ? images.esimgo
                  : row?.vendorType === "esimVault"
                    ? images.esimVault
                    : images.airalo
              }
              alt="offer-icon"
              width={
                row?.vendorType == "esimgo"
                  ? 35
                  : row?.vendorType == "esimVault"
                    ? 50
                    : 50
              }
            />
          </div>
          <span className="ms-2">{row?.vendorType}</span>
        </div>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: <span className="dataTable-text">{t("table.simid")}</span>,
      key: "sims.0.iccid",
      selector: (row) => (
        <span className="column-text">{row?.sims[0]?.iccid}</span>
      ),
      sortable: true,
      width: "250px",
    },
    {
      name: <span className="dataTable-text">{t("table.username")}</span>,
      key: "userId.name",
      cell: (row) => (
        <span className="text-start column-text">{row?.userId?.name ?? row?.paymentId?.name}</span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="dataTable-text">{t("table.packageName")}</span>,
      key: "package",
      selector: (row) => row?.package,
      cell: (row) => (
        <span className="text-start column-text">
          {row.package && row.package?.length > 35
            ? `${row.package.slice(0, 35)}...`
            : row.package}
        </span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="dataTable-text"> {t("table.expiration")}</span>,
      // key: "created_at",
      selector: (row) => (
        <span className="column-text">
          {new Date(
            new Date(row?.created_at).getTime() +
            row?.validity * 24 * 60 * 60 * 1000
          ).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </span>
      ),
      width: "180px",
    },
    {
      name: <span className="dataTable-text">{t("table.validDays")}</span>,
      key: "validity",
      selector: (row) => (
        <span className="column-text">
          {row?.validity} {t("home.part21")}
        </span>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: <span className="dataTable-text">{t("table.remainingData")}</span>,
      selector: (row) => (
        <span className="text-start column-text">
        {row && row.dataUsage && row.dataUsage.remaining && row?.data.replace("GB", "").trim() > 0
          ? `${(row.dataUsage.remaining / 1024).toFixed(2)} ${row?.data.replace("GB", "").trim() > 0 ? t("home.part22") : ""}`
          : `${row?.data.replace("GB", "").trim() > 0 ? row?.data.replace("GB", "").trim() : t("home.part33")} ${row?.data.replace("GB", "").trim() > 0 ? t("home.part22") : ""}`}
      </span>
      ),
      width: currentLocalization == "in" || currentLocalization == "fr" ? "210px" : "185px",
    },
    {
      name: <span className="dataTable-text">{t("table.Data")}</span>,
      key: "data",
      selector: (row) => (
        <span className="column-text">
          {row?.data.replace("GB", "").trim() > 0 ? row?.data.replace("GB", "").trim() : t("home.part33")} {row?.data.replace("GB", "").trim() > 0 && t("home.part22")}
        </span>
      ),
      sortable: true,
      width: "90px",
    },
    {
      name: <span className="dataTable-text">{t("table.orignalPrice")}</span>,
      key: "discountedPrice",
      selector: (row) => (
        <span className="text-start column-text">
          ${row.event === 'topup' ? parseFloat(row?.price / 100).toFixed(2) : parseFloat(row?.price).toFixed(2)}
        </span>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: <span className="dataTable-text">{t("table.Discount")}</span>,
      key: "discountedPer",
      selector: (row) => (
        <span className="text-start column-text">
          {row.discountedPer ? row.discountedPer + "%" : '-'}
        </span>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: <span className="dataTable-text">{t("table.Price")}</span>,
      key: "price",
      selector: (row) => (
        <span className="text-start column-text">
          ${(row?.discountedPrice ? parseFloat(row?.discountedPrice).toFixed(2)
            : parseFloat(row?.price).toFixed(2))}
        </span>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: <span className="dataTable-text">{t("table.Status")}</span>,
      selector: (row) => (
        <h5>
          <Badge
            bg={
              new Date() - new Date(row?.created_at) <=
                row.validity * 24 * 60 * 60 * 1000
                ? "success"
                : "danger"
            }
          >
            {new Date() - new Date(row?.created_at) <=
              row?.validity * 24 * 60 * 60 * 1000
              ? "Active"
              : "Expire"}
          </Badge>
        </h5>
      ),
      width: "120px",
    },
  ];
  const handleSearchChange = (newSearchValue) => {
    setSearch(newSearchValue);
  };
  const handleSort = async (column, direction) => {
    const nestedSort = (obj, path) => {
      const value = path
        .split(".")
        .reduce(
          (value, key) =>
            value && value[key] !== undefined ? value[key] : null,
          obj
        );
      if (path === "data") {
        return parseFloat(value.replace("GB", "").trim());
      }

      return value;
    };

    const sortedData = [...packages].sort((a, b) => {
      const aValue = nestedSort(a, column.key);
      const bValue = nestedSort(b, column.key);

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setSortedUsers(sortedData);
  };
  const getPackages = async (startDate = null, endDate = null, search = "") => {
    setLoading(true)
    try {
      const usersResponse = await getPackagesDetails({ startDate, endDate, search });
      if (usersResponse.success) {
        setPackages(usersResponse?.data.userWithPackageId);
        setSortedUsers(usersResponse?.data.userWithPackageId);
        setStartDate(usersResponse.data.startDate);
        setEndDate(usersResponse.data.endDate);
        if(!minDate) setMinDate(usersResponse.data.startDate)
        if (!appliedStartDate && !appliedEndDate) {
          setStartDate(usersResponse.data.startDate);
          setEndDate(usersResponse.data.endDate);
        }
      }
    } catch (err) {
      if (err.response?.status == 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        localStorage.clear();
        navigate("/log-in");
      } else {
        console.log("err", err);
        const id = toast.error(err.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
    }
    getPackages(appliedStartDate, appliedEndDate, search);
  }, [appliedStartDate, appliedEndDate, currentLocalization, search]);

  const handleApplyFilter = () => {
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
    getPackages(appliedStartDate, appliedEndDate, search);
  };
  return (
    <Sidebar>
      <Container>
        <Row className="d-flex align-items-center justify-content-between">
          <Col xs="auto">
            <div
              className={
                currentLocalization === "ar" ? "text-end" : "text-start"
              }
            >
              <h2 className="package-h2"> {t(
                user && user.role === "user"
                  ? "dataUsage.myPackage"
                  : "dataUsage.sim"
              )}</h2>            </div>
          </Col>
          <Col xs="auto" className="d-flex justify-content-end align-items-center flex-wrap gap-2" >
            {user && user.role === 'partner' && (<>
              {/* <Search
                placeholder="Search"
                onSearchChange={handleSearchChange}
              /> */}
              <DateFilter
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                initialStartDate={startDate}
                initialEndDate={endDate}
                minDate={minDate}
              />
              <ApplyFilterButton onApply={handleApplyFilter} />
            </>)}

          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-4">
              <DataTables
                columns={columns}
                data={sortedUsers.length ? sortedUsers : packages}
                loading={loading}
                handleSort={handleSort}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Sidebar>
  );
};

export default MyPackage;
