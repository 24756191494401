import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import Container from "react-bootstrap/esm/Container";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Bar, Line } from "react-chartjs-2";
import Sidebar from "../../component/Sidebar/SideBar";
import BuyPackages from "../../assets/images/Icon.svg";
import BuySims from "../../assets/images/simsborder.svg";
import TotalSale from "../../assets/images/Icon (2).svg";
import info from "../../assets/images/info.svg"
import TotalCustomers from "../../assets/images/Icon (1).svg";
import TotalCommission from "../../assets/images/Icon (3).svg";
import { GoDotFill } from "react-icons/go";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import getUsersCardData from "../../services/packages/getUsersCardData";
import DateFilter from "../../component/DateFilter";
import ApplyFilterButton from "../../component/ApplyFilter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const AnalyticsPage = () => {
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  const [monthlyData, setMonthlyData] = useState("");
  const [monthlyCommision, setMonthlyCommision] = useState("");
  const [commission, setCommission] = useState("");
  const [monthlySalesCommision, setmonthlySalesCommision] = useState([]);
  const [packageCounts, setpackageCounts] = useState('');
  const [userCounts, setUserCounts] = useState('');
  const [topupCounts, setTopupCounts] = useState('');
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minDate, setMinDate] = useState("");
  const [appliedStartDate, setAppliedStartDate] = useState('');
  const [appliedEndDate, setAppliedEndDate] = useState('');
  const navigate = useNavigate();

  const fetchData = async (startDate = null, endDate = null) => {
    setLoading(true);
    try {
      const [usersResponse] = await Promise.all([
        getUsersCardData({ startDate, endDate }),
      ]);

      if (usersResponse.success) {
        setUserCounts(usersResponse.data.userCount);
        setTopupCounts(usersResponse.data.topupCount);
        setpackageCounts(usersResponse.data.packageCount);
        setMonthlyData(usersResponse.data.totalPrice);
        setMonthlyCommision(usersResponse.data.commission);
        setCommission(usersResponse.data.commissionAmount || 0)
        setmonthlySalesCommision(usersResponse.data.monthlySalesCommision);
        setStartDate(usersResponse.data.startDate);
        if (!minDate) setMinDate(usersResponse.data.startDate)
        setEndDate(usersResponse.data.endDate);
        if (!appliedStartDate && !appliedEndDate) {
          setStartDate(usersResponse.data.startDate);
          setEndDate(usersResponse.data.endDate);
        }
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(appliedStartDate, appliedEndDate);
  }, [appliedStartDate, appliedEndDate]);

  const handleApplyFilter = () => {
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
  };
  const barData = {
    labels: monthlySalesCommision.map((item) => {
      const [year, month] = item.month.split("-");
      const date = new Date(year, month - 1);
      return date.toLocaleString("default", { month: "long", year: "numeric" });
    }),
    datasets: [
      {
        label: "Total Sales",
        data: monthlySalesCommision.map((item) => parseFloat(item.totalPrice)),
        backgroundColor: "#073d38",
      },
      {
        label: "Commission",
        data: monthlySalesCommision.map((item) => parseFloat(item.commission)),
        backgroundColor: "#04e784",
      },
    ],
  };

  return (
    <Sidebar>
      <Container>
        <Row className="d-flex align-items-center justify-content-between">
          <Col xs="auto">
            <div
              className={
                currentLocalization === "ar" ? "text-end" : "text-start"
              }
            >
              <h2 className="package-h2">{t("dataUsage.Analytics")}</h2>
            </div>
          </Col>
          <Col xs="auto" className="d-flex justify-content-end align-items-center gap-2 flex-wrap" style={{padding:'0px'}}>
            <DateFilter
              setStartDate={setStartDate}
              setEndDate={setEndDate} 
              initialStartDate={startDate}
              initialEndDate={endDate}
              minDate={minDate}
            />
            <ApplyFilterButton onApply={handleApplyFilter} />
          </Col>
        </Row>
      </Container>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={30} sx={{ color: "black" }} thickness={4.6} />
        </Box>
      ) : (
        <>
          <Container>
            <Row className="mt-2">
              <Col xl={4} lg={6} md={12} xs={12} className="text-start mt-2">
                <div className="d-flex aligin-items-center ps-3 pt-3 pb-3 analytics-card-box"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/users-listing")}
                >
                  <img
                    src={TotalCustomers}
                    alt="Total Customers Icon"
                    className="customer-icon"
                  />
                  <div className="ps-3">
                    <h5 className="card-value-h5">{userCounts}</h5>
                    <h6 className="card-value-h6">{t("Total Customers")}</h6>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={6} md={12} xs={12} className=" text-start mt-2">
                <div className="d-flex aligin-items-center ps-3 analytics-card-box pt-3 pb-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/my-package")}
                >
                  <img
                    src={BuySims}
                    alt="Total Customers Icon"
                    className="customer-icon"
                  />
                  <div className="ps-3">
                    <h5 className="card-value-h5">{packageCounts}</h5>
                    <h6 className="card-value-h6">{t("Bought Sims")}</h6>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={6} md={12} xs={12} className=" text-start mt-2">
                <div className="d-flex aligin-items-center ps-3 analytics-card-box pt-3 pb-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/topup-listing")}
                >
                  <img
                    src={BuyPackages}
                    alt="Total Customers Icon"
                    className="customer-icon"
                  />
                  <div className="ps-3">
                    <h5 className="card-value-h5">{topupCounts}</h5>
                    <h6 className="card-value-h6">{t("Bought Packages")}</h6>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={6} md={12} xs={12} className="text-start mt-2">
                <div className="d-flex aligin-items-center ps-3 pt-3 pb-3 analytics-card-box"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/transactions-listing")}
                >
                  <img
                    src={TotalSale}
                    alt="Total Customers Icon"
                    className="customer-icon"
                  />
                  <div className="ps-3">
                    <h5 className="card-value-h5">{`$${monthlyData || "0.00"
                      }`}</h5>
                    <h6 className="card-value-h6">{t("Total Sales")}</h6>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={6} md={12} xs={12} className="mt-2 text-start">
                <div className="d-flex aligin-items-center ps-3 pt-3 pb-3 analytics-card-box"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/transactions-listing")}
                >
                  <div className="d-flex">
                    <img
                      src={TotalCommission}
                      alt="Total Commission Icon"
                      className="customer-icon"
                    />
                    <div className="ps-3">
                      <h5 className="card-value-h5">{`$${monthlyCommision || "0.00"
                        }`}</h5>
                      <h6 className="card-value-h6">{t("Total Commission")}</h6>
                    </div>
                  </div>
                  <div className="info-container">
                    <img src={info} alt="Info" className="info-icon" />
                    <span className="info-text">
                      On Minimum Sale: <strong>${commission}</strong>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Box
              sx={{
                mt: 3,
                p: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#ffffff",
              }}
            >
              <Row>
                <Col>
                  <div
                    className={
                      currentLocalization === "ar"
                        ? "text-end d-flex justify-content-between align-items-center mb-3 flex-row-reverse ps-2 pe-2 flex-wrap"
                        : "text-start d-flex justify-content-between align-items-center mb-3 ps-2 pe-2 flex-wrap"
                    }
                  >
                    <h2 className="package-h4 mt-2">
                      {t("Total Sales & Commission")}
                    </h2>
                    <div className="d-flex gap-3 mt-2">
                      <div>
                        <GoDotFill size={25} color="#054940" />
                        <span className="buy-package-span">
                          {t("Total Sales")}
                        </span>
                      </div>
                      <div>
                        <GoDotFill size={25} color="#00E886" />
                        <span className="buy-package-span">
                          {t("Total Commission")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      currentLocalization === "ar" ? "text-end" : "text-start"
                    }
                  ></div>
                </Col>
              </Row>
              <ChartWrapper>
                <Bar
                  data={barData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false, // This disables the legend
                      },
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          title: function (tooltipItems) {

                            return tooltipItems[0].label;
                          },
                          label: function (tooltipItem) {
                            const monthData = monthlySalesCommision[tooltipItem.dataIndex];
                            const totalSales = monthData.totalPrice;
                            const commission = monthData.commission;

                            return [
                              `Total Sales: $${totalSales}`,
                              `Commission: $${commission}`,
                            ];
                          },
                          afterLabel: function (tooltipItem) {
                            const monthData = monthlySalesCommision[tooltipItem.dataIndex];
                            const dailySales = monthData.dailySales;

                            // Sort the daily sales by date
                            const sortedDailySales = Object.keys(dailySales)
                              .sort((a, b) => new Date(a) - new Date(b)) // Sort by date
                              .map((day) => {
                                const formattedDate = new Date(day).toLocaleDateString();
                                return `${formattedDate}: $${dailySales[day]}`;
                              });

                            return `\nDaily Sales:\n${sortedDailySales.join("\n")}`;
                          },
                        },
                        displayColors: false, // Disable dataset color boxes in the tooltip
                      },
                    },
                    elements: {
                      bar: {
                        borderWidth: 2,
                        borderColor: "#ffffff",
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          color: "#9e9e9e",
                          callback: function (value) {
                            return `$${value}`;
                          },
                        },
                        grid: {
                          color: "#e0e0e0",
                        },
                      },
                      x: {
                        ticks: {
                          color: "#9e9e9e",
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                  }}
                />

              </ChartWrapper>
            </Box>
          </Container>
        </>
      )}
    </Sidebar>
  );
};

const ChartWrapper = ({ children }) => (
  <Box sx={{ width: "100%", height: 500, margin: "0 auto" }}>{children}</Box>
);

export default AnalyticsPage;
