import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import gleesim_bg_green from "../../assets/images/gleesim_bg_green.svg";
import gleesim_bg_white from "../../assets/images/gleesim_bg_white.svg";
import socialMedia1Bg from "../../assets/images/Social Media post.svg";
import socialMedia2Bg from "../../assets/images/Social Media post 2.svg";
import docx from "../../assets/images/docx.svg";
import SocialMedia4 from "../../assets/images/Gleesim image 4.png";
import SocialMedia5 from "../../assets/images/Gleesim image 5.png";
import SocialMedia6 from "../../assets/images/Gleesim image 6.png";
import gleeModalWhite from "../../assets/images/glee-modal-white.png";
import SocialMedia2 from "../../assets/images/Social Media post 2.png";
import gleeModalGreen from "../../assets/images/glee-modal-green.png";
import doc1 from "../../assets/docs/HOW prepaid travel eSIM works.docx";
import doc2 from "../../assets/docs/The difference between traditional roaming sim vs prepaid travel eSIM.docx";
import doc3 from "../../assets/docs/Why Gleesim is perfect travel eSIM.docx";
import SocialMedia from "../../assets/images/Social Media post.png";
import ResourcesModal from "../../component/ResourcesModal";
import MobileCard from "./MobileCard";
import DesktopImageCard from "./DesktopImageCard";
import DesktopDocCard from "./DesktopDocCard";
import { AuthUserContext } from "../../context/context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import routes from "../../configs/api";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";
import resourceUpload from "../../services/partner/resourceUpload";
import resourceFetch from "../../services/partner/resourceFetch";
import { toast } from "react-toastify";

const Resources = () => {
  const docsName = {
    doc1: "How prepaid travel eSIM works",
    doc2: "The difference between traditional roaming sim vs prepaid travel eSIM",
    doc3: "Why Gleesim is perfect travel eSIM",
  };
  const navigate = useNavigate();
  const { currentLocalization, setToastId, setUser, user } =
    useContext(AuthUserContext);
  const { t } = useTranslation();
  const [modalUrl, setModalUrl] = useState(false);
  const [loading, setLoading] = useState(true);
  const [resources, setResources] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Function to fetch resources from API
  const fetchResources = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    setLoading(true);
    try {
      const response = await resourceFetch();
      setResources(response.data);
    } catch (error) {
      const id = toast.error(error, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
      setToastId(id);
    } finally {
      setLoading(false);
    }
  };
  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    const maxFileSize = 15 * 1024 * 1024;
    const filesExceedingLimit = files.filter((file) => file.size > maxFileSize);

    if (filesExceedingLimit.length > 0) {
      let errorMessage = "";
      if (filesExceedingLimit.length && files.length === 1) {
        errorMessage = t("resources.singleFile");
      } else {
        errorMessage = t("resources.MultiFile");
      }

      const id = toast.error(errorMessage, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
      setToastId(id);
      setSelectedFiles([]);
      return;
    }

    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));

      try {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 500));

        const response = await resourceUpload(formData);
        setSelectedFiles([]);

        const id = toast.success("Files uploaded successfully!", {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
        setToastId(id);
        setLoading(false);

        // setResources((prevResources) => [...prevResources, ...response.data]);
        fetchResources();
      } catch (error) {
        const id = toast.error(error.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
        setToastId(id);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  const images = [
    gleesim_bg_green,
    gleesim_bg_white,
    socialMedia1Bg,
    socialMedia2Bg,
    SocialMedia4,
    SocialMedia5,
    SocialMedia6,
    gleeModalWhite,
    SocialMedia,
    SocialMedia2,
    gleeModalGreen,
  ];

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = images.map(
        (src) =>
          new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
            img.onerror = reject;
          })
      );
      await Promise.all(imagePromises);
      setLoading(false);
    };
    loadImages();
  }, []);

  return (
    <Sidebar>
      <Container>
        <Row>
          <Col className="px-4">
            <Row
              className="d-flex align-items-center justify-content-between "
              style={{ marginTop: "65px" }}
            >
              <Col
                className={
                  currentLocalization === "ar" ? "text-end" : "text-start"
                }
              >
                <h2 className="package-h2 mt-0">{t("dataUsage.all")}</h2>
              </Col>
              <Col xl={8} lg={12} md={11} xs={12} className="mb-2">
                <Row className="d-flex align-items-center justify-content-end">
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    xs={12}
                    className="d-flex justify-content-center pe-md-0 mt-md-0 mt-3"
                  >
                    {user?.role === "admin" && (
                      <button
                        className="btn-add-new btn-add-new-font"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        {t("resources.addResource")}
                      </button>
                    )}
                    <input
                      type="file"
                      accept=".doc, .docx, .jpg, .jpeg, .png"
                      id="fileInput"
                      multiple
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <ResourcesModal modalUrl={modalUrl} setModalUrl={setModalUrl} />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner
            animation="border"
            style={{
              width: "3rem",
              height: "3rem",
              borderWidth: "0.25em",
              borderTopColor: "#054940",
              borderRightColor: "#054940",
              borderBottomColor: "#054940",
              borderLeftColor: "#ccc",
            }}
          />
        </div>
      ) : resources.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            fontSize: "1.5rem",
            color: "#888",
          }}
        >
          <p>{t("resources.notFound")}</p>
        </div>
      ) : (
        <>
          <Container className="desktop mt-2">
            <div className="bg-white main-wrapper">
              {resources.map((resource, index) =>
                resource.originalname.includes(".docx") ? (
                  <DesktopDocCard
                    key={index}
                    fileName={resource.originalname}
                    link={`${process.env.REACT_APP_API_URL}/${resource.path}`}
                    setResources={setResources}
                    resourceId={resource._id}
                  />
                ) : (
                  <DesktopImageCard
                    key={index}
                    resourceId={resource._id}
                    imageUrl={`${process.env.REACT_APP_API_URL}/${resource.path}`}
                    setModalUrl={setModalUrl}
                    bigImageUrl={`${process.env.REACT_APP_API_URL}/${resource.path}`}
                    name={resource.originalname}
                    setResources={setResources}
                  />
                )
              )}
            </div>
          </Container>

          <Container className="mobile" mt-2 style={{ borderRadius: "12px" }}>
            <div
              className="bg-white mobile-wrapper"
              style={{ padding: "10px 0px" }}
            >
              {resources.map((resource, index) =>
                resource.originalname.includes(".docx") ? (
                  <MobileCard
                    imageUrl={docx}
                    menuOptions={[
                      {
                        label: "Download",
                        image: docx,
                        name: resource.originalname,
                      },
                      ...(user.role === "admin"
                        ? [
                            {
                              label: "Delete",
                              image: ``,
                              name: resource.originalname,
                            },
                          ]
                        : []),
                    ]}
                    isDocs={true}
                    setModalUrl={setModalUrl}
                    fileName={resource.originalname}
                    resourceId={resource._id}
                    setResources={setResources}
                  />
                ) : (
                  <MobileCard
                    imageUrl={`${process.env.REACT_APP_API_URL}/${resource.path}`}
                    menuOptions={[
                      {
                        label: "View",
                        image: `${process.env.REACT_APP_API_URL}/${resource.path}`,
                      },
                      {
                        label: "Download",
                        image: `${process.env.REACT_APP_API_URL}/${resource.path}`,
                        name: resource.originalname,
                      },
                      ...(user.role === "admin"
                        ? [
                            {
                              label: "Delete",
                              image: ``,
                              name: resource.originalname,
                            },
                          ]
                        : []),
                    ]}
                    setModalUrl={setModalUrl}
                    resourceId={resource._id}
                    setResources={setResources}
                  />
                )
              )}
            </div>
          </Container>
        </>
      )}
    </Sidebar>
  );
};

export default Resources;
