import React, { FC } from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import deleteIcon from "../assets/images/delete-icon.svg";


const ConfirmationModal = ({
  modalShow,
  deleteResource,
  confirmationModalClose,
  loading
}) => {
  return (
    <Modal
      show={modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-width"
    >
      <Modal.Body className="p-4 ">
        <div className="d-flex justify-content-center flex-column align-items-center">
          <img src={deleteIcon} alt="deleteIcon" width={150} />
        </div>
        <h4 className="modal-h4 text-center mt-4">
          Are you sure you want to delete this resource ?
        </h4>
        <Row>
          <Col md={6} xs={12}>
            <div
              className="d-grid gap-2"
              onClick={confirmationModalClose}
              disabled={loading}
            >
              <button
                className={`modal-button-cancel mt-4 ${
                  loading ? "disabled-btn" : ""
                }`}
              >
                Cancel
              </button>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="d-grid gap-2">
              <button
                className="modal-button-save mt-4"
                  onClick={deleteResource}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ms-1">Loading...</span>
                  </>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
