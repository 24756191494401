import React, { useContext, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import SignUp from "../page/signUp";
import { AuthUserContext } from "../context/context";
import { useTranslation } from "react-i18next";
import OtpVerification from "../page/otpVerification";
import Login from "../page/login";

function RegistrationPopup({ show, handleClose }) {
  const { currentLocalization, setToastId, toastId } =
    useContext(AuthUserContext);
  const [userSignedUp, setUserSignedUp] = useState(false);
  const [login, setLogin] = useState(false);

  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={() => {
        if (!userSignedUp) {
          setUserSignedUp(false);
          handleClose();
        }
      }}
      centered
      className="registration-modal"
      style={{ borderRadius: "8px" }}
    >
      <Modal.Header closeButton={false} className="custom-header pt-4 -b-4 align-items-center">
        <h2
          className={`login-welcome-registrpopup ${currentLocalization == "ar" && "flex-row-reverse"
            }`}
        >

          {!login ? (
            !userSignedUp ? (
              t("signUp.part1")
            ) : (
              t("otpVerification.part1")
            )
          ) : (
            t("Login your account")
          )}

        </h2>
        <button
          type="button"
          className="custom-close-btn"
          onClick={() => {
            setUserSignedUp(false);
            handleClose();
          }}          aria-label="Close"
        >
          &times;
        </button>
      </Modal.Header>

      <Modal.Body className="no-padding">
        <Row>
          <Col>
            {!login ? (
              !userSignedUp ? (
                <SignUp
                  hideSidebar={true}
                  isModal={true}
                  setLogin={setLogin}
                  setUserSignedUp={() => setUserSignedUp(true)}
                  handleClose={handleClose}
                />
              ) : (
                <OtpVerification
                  hideSidebar={true}
                  isModal={true}
                  handleClose={handleClose}
                />
              )
            ) : (
              <Login hideSidebar={true} isModal={true} setLogin={setLogin} 
              handleClose={handleClose}
              />
            )}


          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default RegistrationPopup;
