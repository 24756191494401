import { Box } from "@mui/material";
import { memo, useEffect, useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import { Bar, Line } from "react-chartjs-2";
import { getPartnersChart } from "../../../services/partner/getAllPartner";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

const Graph = () => {
  console.log("rendered");
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Price",
        data: [],
        backgroundColor: "#073d38",
      },
    ],
  });
  const [loadingGraphData, setLoadingGraphData] = useState(false);
  const [count, setCount] = useState(5);
  const [selectedOption, setSelectedOption] = useState("top");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleCountChange = (e) => {
    setCount(parseInt(e.target.value, 10));
  };

  const ChartWrapper = ({ children }) => (
    <Box sx={{ width: "100%", height: 500, margin: "0 auto" }}>
      {loadingGraphData ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner
            animation="border"
            style={{
              width: "3rem",
              height: "3rem",
              borderWidth: "0.25em",
              borderTopColor: "#054940",
              borderRightColor: "#054940",
              borderBottomColor: "#054940",
              borderLeftColor: "#ccc", // Light color for the uncolored part of the spinner
            }}
          />
        </div>
      ) : (
        children
      )}
    </Box>
  );

  useEffect(() => {
    const fetchChartData = async () => {
      setLoadingGraphData(true);
      try {
        const response = await getPartnersChart({});
        const partner = [...response.data];

        // Filter, sort, and slice based on selectedOption and count
        const filteredData = partner
          .filter(
            (item) =>
              selectedOption !== "least" || parseFloat(item.totalPrice) > 0 // Exclude zero for "least"
          )
          .sort((a, b) => {
            const priceA = parseFloat(a.totalPrice) || 0;
            const priceB = parseFloat(b.totalPrice) || 0;
            return selectedOption === "top" ? priceB - priceA : priceA - priceB;
          })
          .slice(0, count);

        setBarData({
          labels: filteredData.map((item) => item.companyName),
          datasets: [
            {
              label: "Total Price",
              data: filteredData.map((item) => parseFloat(item.totalPrice)),
              backgroundColor: "#073d38",
            },
          ],
        });
      } catch (err) {
        console.error("Error fetching chart data:", err);
      } finally {
        setLoadingGraphData(false);
      }
    };

    fetchChartData();
  }, [selectedOption, count]); // Add selectedOption and count as dependencies

  return (
    <>
      {/* <Row>
        <Col> */}
          <Row
            className="d-flex align-items-center justify-content-between " >
            <Col className="text-start mb-3">
              <div className="col-md-6">
                <select
                  id="singleDropdown"
                  className="form-select"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="top">Top Performing Partners</option>
                  <option value="least">Least Performing Partners</option>
                </select>
              </div>
            </Col>
            <Col className="d-flex align-items-center justify-content-end mr-2 mb-3">
              <select
                id="countDropdown"
                className="form-select"
                value={count.toString()}
                onChange={handleCountChange}
                style={{ width: "70px", marginRight: "15px" }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </Col>
          {/* </Row>
        </Col> */}

        <ChartWrapper>
          <Bar
            data={barData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: { display: false },
                tooltip: {
                  enabled: true,
                  mode: "nearest",
                  intersect: false,
                  callbacks: {
                    label: function (tooltipItem) {
                      const totalPrice =
                        tooltipItem.chart.data.datasets[0].data[
                          tooltipItem.dataIndex
                        ];
                      return `$${totalPrice.toFixed(2)}`;
                    },
                    labelColor: function (tooltipItem, chart) {
                      return {
                        borderColor: "#073d38",
                        backgroundColor: "#073d38",
                      };
                    },
                  },
                },
              },
              elements: {
                bar: {
                  borderWidth: 2,
                  borderColor: "#ffffff",
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    color: "#9e9e9e",
                    callback: function (value) {
                      return `$${value}`;
                    },
                  },
                  grid: {
                    color: "#e0e0e0",
                  },
                },
                x: {
                  ticks: {
                    color: "#9e9e9e",
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        </ChartWrapper>
      </Row>
    </>
  );
};

export default memo(Graph);
