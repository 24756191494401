import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
const LabelModal = ({
  modalShow,
  setModalShow,
  error,
  addLoading,
  setError,
  setAddLabel,
  addLabel,
  handleSubmit,
  setSimForLabel,
}) => {
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  return (
    <div>
      <div>
        {modalShow ? (
          <Modal
            show={modalShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-width"
          >
            <Modal.Body
              className={`p-4 ${
                currentLocalization == "ar"
                  ? "d-flex flex-column justify-content-end"
                  : "d-flex flex-column justify-content-start"
              }`}
            >
              <h4
                className={`modal-h4  ${
                  currentLocalization == "ar" ? "text-end" : "text-start"
                }`}
              >
                {t("detailsPage.part16")}
              </h4>
              <input
                className={`${
                  currentLocalization == "ar"
                    ? "modal-input-right mt-4"
                    : "modal-input mt-4"
                }`}
                placeholder={t("detailsPage.part16")}
                type="text"
                value={addLabel}
                onChange={(e) => setAddLabel(e.target.value)}
              />
              {error && !addLabel && (
                <p className="text-danger mt-2">{t("detailsPage.part17")}</p>
              )}
              <Row>
                <Col md={6} xs={12}>
                  <div
                    className="d-grid gap-2"
                    onClick={() => {
                      setModalShow(false);
                      setError(false);
                      setAddLabel("");
                      setSimForLabel(null);
                    }}
                  >
                    <button className="modal-button-cancel mt-4" size="lg">
                      {t("dataUsage.Cancel")}
                    </button>
                  </div>
                </Col>
                <Col md={6} xs={12}>
                  <div className="d-grid gap-2">
                    <button
                      className="modal-button-save mt-4"
                      size="lg"
                      onClick={handleSubmit}
                      disabled={addLoading}
                    >
                      {addLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="ms-1">{t("login.part16")}...</span>
                        </>
                      ) : (
                        t("detailsPage.part18")
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default LabelModal;
