export const downloadFile = async (url, name) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const objectURL = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objectURL;
    link.download = name;
    link.click();

    URL.revokeObjectURL(objectURL);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

export const changeDataToNumber = (topups) =>{
  return topups?.length > 0 
  ? topups.map((topUp) => ({
      ...topUp, 
      data: topUp.data === -0.001 
          ? topUp.data 
          : parseInt(topUp.data.replace(/\D/g, ''), 10) || 0
  })) 
  : [];    
}