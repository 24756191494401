import axios from "axios";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";
import routes from "../../configs/api";

const getPackagesDetails = (selectedMonth, newSearchValue) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  return new Promise((resolve, reject) => {
    let URL = `${routes.PACKAGES_LIST}`;
    if (selectedMonth && selectedMonth !== "") {
      URL += `?month=${encodeURIComponent(selectedMonth)}`;
    }
    if (newSearchValue && newSearchValue !== "") {
      const separator = URL.includes("?") ? "&" : "?";
      URL += `${separator}search=${encodeURIComponent(newSearchValue)}`;
    }

    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getPackagesDetails;
