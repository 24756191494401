import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DateFilter = ({
  setStartDate,
  setEndDate,
  initialStartDate,
  initialEndDate,
  minDate = null,
}) => {
  const [localStartDate, setLocalStartDate] = useState(initialStartDate);
  const [localEndDate, setLocalEndDate] = useState(initialEndDate);
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    const today = new Date();
    setMaxDate(today.toISOString().split("T")[0]);
  }, []);

  useEffect(() => {
    setLocalStartDate(initialStartDate);
    setLocalEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  const getDate = (date) => {
    let givenDate = new Date(date);
    let utcDate = new Date(
      Date.UTC(
        givenDate.getFullYear(),
        givenDate.getMonth(),
        givenDate.getDate()
      )
    );
    const formattedData = utcDate.toISOString().split("T")[0];
    return date === null ? null : formattedData;
  };
  const handleStartDateChange = (date) => {
    const formattedData = getDate(date);
    setLocalStartDate(formattedData);
    setStartDate(formattedData);
  };

  const handleEndDateChange = (date) => {
    const formattedData = getDate(date);
    setLocalEndDate(formattedData);
    setEndDate(formattedData);
  };

  return (
    <div className="date-filter">
      <div style={{ paddingRight: "6px" }}>
        <DatePicker
          calendarClassName="padding-right"
          className="padding-right"
          selected={localStartDate}
          placeholderText="From"
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleStartDateChange}
          dateFormat={"dd/MM/yyyy"}
          toggleCalendarOnIconClick={true}
          customInput={
            <CustomInput
              value={localEndDate || ""}
              onChange={handleEndDateChange}
              placeholder={"From"}
            />
          }
        />
      </div>
      <DatePicker
        clearButtonTitle="Clear"
        type="date"
        placeholderText="To"
        selected={localEndDate}
        maxDate={maxDate}
        minDate={initialStartDate}
        onChange={handleEndDateChange}
        dateFormat={"dd/MM/yyyy"}
        customInput={
          <CustomInput
            value={localEndDate || ""}
            onChange={handleEndDateChange}
            placeholder="To"
          />
        }
      />
    </div>
  );
};

export default DateFilter;

const CustomInput = React.forwardRef(
  ({ value, onChange, onClick, placeholder }, ref) => {
    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      const date = inputValue ? new Date(inputValue) : null;
      onChange(date);
    };
    console.log("placeholder ", placeholder);
    return (
      <div style={{ position: "relative" }}>
        <input
          type="text"
          placeholder={placeholder}
          value={value || ""}
          onChange={handleInputChange}
          onClick={onClick}
          ref={ref}
          style={{
            paddingRight: "30px",
            paddingLeft: "10px",
            border: "1px solid #CCCCCC",
            background: "transparent",
            color: "#333333",
            fontSize: "14px",
            outline: "none",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px",
            width: "150px",
          }}
        />
        <span
          onClick={onClick}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
        >
          <svg
            fill="#000000"
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 100 100"
            xmlSpace="preserve"
          >
            <path
              d="M76,42H24c-1.1,0-2,0.9-2,2v30c0,3.3,2.7,6,6,6h44c3.3,0,6-2.7,6-6V44C78,42.9,77.1,42,76,42z M40,70
	c0,1.1-0.9,2-2,2h-4c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2V70z M54,70c0,1.1-0.9,2-2,2h-4c-1.1,0-2-0.9-2-2v-4
	c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2V70z M54,56c0,1.1-0.9,2-2,2h-4c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2V56z
	 M68,56c0,1.1-0.9,2-2,2h-4c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2V56z M72,26h-5v-2c0-2.2-1.8-4-4-4s-4,1.8-4,4v2
	H41v-2c0-2.2-1.8-4-4-4s-4,1.8-4,4v2h-5c-3.3,0-6,2.7-6,6v2c0,1.1,0.9,2,2,2h52c1.1,0,2-0.9,2-2v-2C78,28.7,75.3,26,72,26z"
            />
          </svg>
        </span>
      </div>
    );
  }
);
