import axios from "axios";
import routes from "../../configs/api";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";

const deleteResourceEndpoint = (resourceId) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  return new Promise((resolve, reject) => {
    const URL = `${routes.DELETE_RESOURCE}/${resourceId}`;
    axios
      .delete(URL)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default deleteResourceEndpoint;
