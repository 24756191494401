import axios from 'axios';
import { toast } from "react-toastify";
import routes from "../configs/api";
import { AUTH_TOKEN_KEY, USER_DETAILS, SIM_PURCHASE, TOPUP } from "../constants/localstorageKeys";

const HandleGoogleAuth = async (credentialResponse, navigate, toastMessage, isLogin, isModal = null, handleClose = null, setUser = null) => {
  try {
    const referrerId = localStorage.getItem("referrer");
    const response = await axios.post(routes.GOOGLE_LOGIN, {
      token: credentialResponse.access_token || '',
      referrerDomain: referrerId || "",
    });

    const loginType = "google";
    if (loginType === "google" && response) {
      const userDetails = {
        id: response?.data?.data?.id,
        email: response?.data?.data?.email,
        name: response?.data?.data?.name,
        role: response?.data?.data?.role,
        phoneNo: response?.data?.data?.phone_no,
        otpVerified: response?.data?.data?.otpVerified,
        countries: response?.data?.data?.countries,
        referralCode: response?.data?.data?.referralCode,
        parent_id: response?.data?.data?.parent_id ?? null,
      };
      localStorage.setItem(USER_DETAILS, JSON.stringify(userDetails));
      localStorage.setItem(AUTH_TOKEN_KEY, response?.data?.data?.token);
      if (isLogin) {
        localStorage.setItem(TOPUP, response?.data?.data?.has_SimTopup);
        localStorage.setItem(SIM_PURCHASE, response?.data?.data?.has_topup);
      }

      toast.success(toastMessage, {
        autoClose: 2000,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
      setUser(userDetails)

      if (isModal) {
        handleClose();
      } else if (userDetails.role === "user") {
        navigate("/data-usage");
      } else if (userDetails.role === "admin" || userDetails.role === "partner") {
        navigate("/users-listing");
      } else if (userDetails.role === "member") {
        navigate("/content-management");
      }
    } else {
      toast.error("Operation failed: " + response.data.message);
    }
  } catch (error) {
    console.log('erro', error);
    toast.error("Google authentication . Please try again.");
  }
};

export default HandleGoogleAuth;
