import axios from "axios";
import routes from "../../configs/api";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";

const getAllPartner = ({
  search = "",
  sortField = "createdAt",
  sortOrder = "desc",
}) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  return new Promise((resolve, reject) => {
    const URL = `${routes.GET_ALL_PARTNER}?search=${search}&sortField=${sortField}&sortOrder=${sortOrder}`;
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getPartnersChart = ({
  search = "",
  sortField = "createdAt",
  sortOrder = "desc",
}) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  return new Promise((resolve, reject) => {
    const URL = `${routes.GET_ALL_PARTNER}?search=${search}&sortField=${sortField}&sortOrder=${sortOrder}`;
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export { getAllPartner, getPartnersChart };
