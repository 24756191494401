import React, { useContext, useState } from "react";
import dots from "../../assets/images/threeeDot.svg";
import CustomMenu from "../../component/CustomMenu";
import { downloadFile } from "../../helper";
import deleteResourceEndpoint from "../../services/partner/deleteResource";
import { AuthUserContext } from "../../context/context";
import { toast } from "react-toastify";
import ConfirmationModal from "../../component/ConfirmationModal";
const MobileCard = ({
  imageUrl,
  menuOptions,
  setModalUrl,
  isDocs = false,
  fileName,
  resourceId,
  setResources
}) => {
  const { user, setToastId } = useContext(AuthUserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow]= useState(false);
  const handleMobileItemClick = (item) => {
    if (item.label === "View") {
      setModalUrl(item.image);
    } else if(item.label === "Delete") {
    setModalShow(true)
    }else{
      downloadFile(item.image, item.name);
    }
    setIsMenuOpen(false);
  };
  const confirmationModalClose = () => {
    setModalShow(false);
  };
  const deleteResource = async () => {
    try {
      setLoading(true)
      await deleteResourceEndpoint(resourceId);
      setResources((prevResources) =>
        prevResources.filter((resource) => resource._id !== resourceId)
      );
      setModalShow(false)
      toast.success("Resource has been deleted successfully.")
    } catch (error) {
      const id = toast.error(error, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
      setToastId(id);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="mobile-docs-card-wrapper" style={{ width: "100%" }}>
      <div className={`${isDocs ? "mobile-docs" : ""} mobile-card `}>
        {isDocs ? (
          <div className="pdf-card">
            <img src={imageUrl} />
            <p className="cardText">{fileName}</p>
          </div>
        ) : (
          <img src={imageUrl} className="" style={{ width: "100%" }} />
        )}
        <div className="mobile-card-dots">
          <img
            src={dots}
            className=""
            width={30}
            height={30}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
          <CustomMenu
            isMenuOpen={isMenuOpen}
            handleItemClick={handleMobileItemClick}
            menuItems={menuOptions}
            user={user}
          />
        </div>
      </div>
      <ConfirmationModal
        loading={loading}
        deleteResource={deleteResource}
        confirmationModalClose={confirmationModalClose}
        modalShow={modalShow}
      />
    </div>
  );
};

export default MobileCard;
