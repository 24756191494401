import React, { useContext, useState } from "react";
import EyeIcon from "../../assets/images/pdf 1.svg";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import { downloadFile } from "../../helper";
import { AuthUserContext } from "../../context/context";
import deleteResourceEndpoint from "../../services/partner/deleteResource";
import { toast } from "react-toastify";
import ConfirmationModal from "../../component/ConfirmationModal";

const DesktopImageCard = ({
  setModalUrl,
  imageUrl,
  bigImageUrl,
  name,
  resourceId,
  setResources,
}) => {
  const { user, setToastId } = useContext(AuthUserContext);
  const [loading, setLoading] = useState(false);
  const [isResourceId, setIsResourceId] = useState("");
  const [modalShow, setModalShow]= useState(false);
  const confirmationModalOpen = (resourceId) => {
    setModalShow(true);
    setIsResourceId(resourceId);
  };
  const confirmationModalClose = () => {
    setModalShow(false);
    setIsResourceId("");
  };
  const deleteResource = async () => {
    try {
      setLoading(true)
      await deleteResourceEndpoint(isResourceId);
      setResources((prevResources) =>
        prevResources.filter((resource) => resource._id !== isResourceId)
      );
      setModalShow(false)
      toast.success("Resource has been deleted successfully.")
    } catch (error) {
      const id = toast.error(error, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
      setToastId(id);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="card-wrapper">
      <img src={imageUrl} className="card-image" width={150} height={150} />
      <div className="overlay"></div>
      <div className={user?.role === "admin" ? "active-icon" : "active-icon-partner"}>
        <div className="images">
          <img src={EyeIcon} onClick={() => setModalUrl(bigImageUrl)} />
          <img
            src={downloadIcon}
            onClick={() => downloadFile(bigImageUrl, name)}
          />
          {user?.role === "admin" && (
            <img
              src={deleteIcon}
              onClick={() => confirmationModalOpen(resourceId)}
            />
          )}
        </div>
      </div>
      <ConfirmationModal
        loading={loading}
        deleteResource={deleteResource}
        confirmationModalClose={confirmationModalClose}
        modalShow={modalShow}
      />
    </div>
  );
};

export default DesktopImageCard;
